import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { NavigationTDS } from "trv-ebus-tcom-reactcomponents"

import { bool, func, string } from "prop-types"

const GlobalHeader = ({ onAlertClick, onMenuToggleHandler }) => {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          ecsNav {
            content
          }
        }
      `}
      render={(data) => (
        <NavigationTDS
          navigation={data.ecsNav.content}
          logo={{
            title: "Sustainability Home Page",
            href: "/",
            linkId: "travelers-home-logo",
            imgSrc:
              "https://www.travelers.com/assets/images/travelers-logo.svg",
            altText: "Travelers logo",
          }}
          searchAction="/search-results"
          searchPlaceholder="Search sustainability"
          sitewideMessagesId="sustainability-sitewide-messages"
          showAlertIcon={false}
          onAlertClick={onAlertClick}
          fixed
          onMenuToggleHandler={onMenuToggleHandler}
        />
      )}
    />
  )
}

export default GlobalHeader

GlobalHeader.propTypes = {
  pageUrl: string,
  showAlert: bool,
  onAlertClick: func,
  onMenuToggleHandler: func,
}
