import React from "react"
import { Head } from "trv-ebus-tcom-reactcomponents"
import Shareaholic from "./shareaholic"
import { object, string } from "prop-types"
import { useStore } from "react-redux"

const DefaultHead = ({
  htmlAttributes,
  canonicalUrl,
  pageInformation,
  tagman,
}) => {
  const store = useStore()
  const utagUrl = store.getState().config.UTAG_URL
  return (
    <>
      <Head htmlAttributes={htmlAttributes}>
        <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
        <link rel="canonical" href={canonicalUrl} />
        {utagUrl && (
          <script type="text/javascript">
            {`(function(a,b,c,d){
        a='${utagUrl}';
        b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();`}
          </script>
        )}
      </Head>
      <Shareaholic />
      {pageInformation}
      {tagman}
    </>
  )
}

export default DefaultHead

DefaultHead.propTypes = {
  htmlAttributes: object,
  canonicalUrl: string,
  pageInformation: object,
  tagman: object,
  utagUrl: string,
}
