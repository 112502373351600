import { useState, useEffect } from "react"

const useWindowWidth = () => {
  const innerWidth = typeof window !== "undefined" ? window.innerWidth : 0
  const [windowWidth, setWindowWidth] = useState(innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return {
    windowWidth,
    isMobile: windowWidth < 768,
    isTablet: windowWidth > 768,
    isDesktop: windowWidth > 1024,
    isLargeDesktop: windowWidth > 1440,
  }
}

export default useWindowWidth
