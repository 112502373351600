import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import PropTypes from "prop-types"

const AnchorLink = ({ isPreview, children, href = false, ...args }) => {
  const externalLinks = [
    "http",
    "www",
    "iw-images",
    "iw-documents",
    "/iw-images",
    "/iw-documents",
    "/downloads",
  ]

  let isExternalLink = false

  externalLinks.forEach((externalLink) => {
    if (href && href.startsWith(externalLink)) {
      isExternalLink = true
    }
  })

  if (isExternalLink || !href) {
    return (
      <a href={href ? href : undefined} {...args}>
        {children}
      </a>
    )
  } else {
    let { target } = args

    if (target === "_blank") {
      return (
        <a href={href} {...args}>
          {children}
        </a>
      )
    }

    if (
      isPreview &&
      (!href.startsWith("preview") || !href.startsWith("/preview"))
    ) {
      href = `/preview${href}`
    }

    return (
      <Link to={href} {...args}>
        {children}
      </Link>
    )
  }
}

const AnchorLinkWithPreview = ({ children, href = "", ...args }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          ecsContentMode {
            content {
              isPreview
            }
          }
        }
      `}
      render={(data) => (
        <AnchorLink
          isPreview={data.ecsContentMode.content.isPreview}
          // eslint-disable-next-line react/no-children-prop
          children={children}
          href={href}
          {...args}
        />
      )}
    />
  )
}

AnchorLink.propTypes = {
  isPreview: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  href: PropTypes.string,
}

AnchorLinkWithPreview.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  href: PropTypes.string,
}

export default AnchorLinkWithPreview
