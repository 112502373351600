import { MessageLocations } from "../constants"

const getCurrentLocation = (path) => {
  if (path && path !== "/") {
    path = path.replace(/\/$/, "") // remove trailing slashes
  }

  switch (path) {
    case "/":
      return MessageLocations.Homepage
    case "/claim-reporting":
      return MessageLocations.ReportAClaim
    case "/contact-us":
      return MessageLocations.ContactUs
    default:
      return MessageLocations.AllPages
  }
}

const getSitewideMessages = (content, path) => {
  if (content && content.siteMessageEnabled !== "on") return null

  const currentLocation = getCurrentLocation(path)
  const messages = content?.siteMessages

  let siteMessages = []
  switch (currentLocation) {
    case MessageLocations.Homepage:
      siteMessages = messages.filter(
        (m) =>
          m.messageLocation === MessageLocations.Homepage ||
          m.messageLocation === MessageLocations.AllPages
      )
      break
    case MessageLocations.ReportAClaim:
      siteMessages = messages.filter(
        (m) =>
          m.messageLocation === MessageLocations.ReportAClaim ||
          m.messageLocation === MessageLocations.AllPages
      )
      break
    case MessageLocations.ContactUs:
      siteMessages = messages.filter(
        (m) =>
          m.messageLocation === MessageLocations.ContactUs ||
          m.messageLocation === MessageLocations.AllPages
      )
      break
    case MessageLocations.AllPages:
      siteMessages = messages.filter(
        (m) => m.messageLocation === MessageLocations.AllPages
      )
      break
  }

  if (siteMessages.length > 0) {
    return {
      messageType: content.messageType,
      siteMessages: siteMessages,
    }
  }

  return null
}

export default getSitewideMessages
