import { componentMapping, circleGraphReplace } from "./componentMapping"

export const updatePullQuote = (data) => {
  if (
    data.ecsPage.slug.includes("drivers-of-sustained-value/ethics-and-values")
  ) {
    const pullQuote = document.querySelectorAll("[class='quote-text']")
    const pullQuoteAttr = document.querySelectorAll(
      "[class='attribution-container']"
    )
    const bulletPoints = pullQuote[0]?.innerHTML
      .toString()
      .split(/(?=[•])/)
      .filter((elem) => elem.includes("•"))
      .map((bullet) => bullet.replaceAll("•&nbsp; ", "").replaceAll("<br>", ""))

    const pullQuoteList = document.createElement("div")
    const pullQuoteTitle = document.createElement("span")
    const pullQuoteUl = document.createElement("ul")
    pullQuoteList.className = "quote"
    pullQuoteTitle.innerHTML = pullQuote[0]?.innerHTML.toString().split("•")[0]

    pullQuoteList.appendChild(pullQuoteTitle)
    pullQuoteList.appendChild(pullQuoteUl)
    pullQuoteList.appendChild(pullQuoteAttr[0])

    const targetTab = document.querySelectorAll("[id$='1-panel']")[0]
    const pullQuoteTarget = Array.from(targetTab.querySelectorAll("pre")).find(
      (el) => el.textContent.trim() === "Pull_Quote_Etihics_Approach"
    )

    bulletPoints.forEach((bulletText, i) => {
      const list = document.createElement("li")
      list.key = i
      list.innerHTML = bulletText
      pullQuoteUl.appendChild(list)
    })

    pullQuoteTarget.insertAdjacentElement("afterend", pullQuoteList)
    pullQuoteTarget.remove()
    document
      .querySelectorAll("[class='specialized-content-print-wrapper']")[0]
      .remove()
  }
}

export const addStaticAccordion = (data, acccRef) => {
  if (data.ecsPage.slug.includes("drivers-of-sustained-value/innovation")) {
    let targetTab = document.querySelectorAll("[id$='5-panel']")[0]
    const accordionTarget = Array.from(targetTab.querySelectorAll("pre")).find(
      (el) => el.textContent.trim() === "Products_Services"
    )
    accordionTarget.insertAdjacentElement("afterend", acccRef.current)
    accordionTarget.remove()
    Array.from(document.querySelectorAll("[class='accordion-interface']"))
      .find((el) => el.id === "Business_Insurance")
      .remove()
  }
}

export const getAccordionDetails = (data, accordionDetails) => {
  if (data.ecsPage.slug.includes("drivers-of-sustained-value/innovation")) {
    const tabDetails =
      data.ecsPage?.content?.components[5]?.segments[0]?.segmentItemDetails[0]
        ?.parameterItemDetails[0]?.dcr?.rows[2]?.columns[0]?.contentAccordion
        ?.rows
    tabDetails.forEach((tab) => {
      accordionDetails.push({
        title: tab.title,
        content: tab.columns[0].bodyContentLite.rows[0].columns[0].text.copy,
      })
    })
  }
  return accordionDetails
}

export const updatePreText = (data) => {
  Object.keys(componentMapping).forEach((component) => {
    const compDetail = componentMapping[component]
    Object.keys(compDetail.pages).forEach((pageAccordion) => {
      if (data.ecsPage.slug.includes(pageAccordion)) {
        const accordions = document.querySelectorAll(compDetail.selector)
        compDetail.pages[pageAccordion].forEach((array) => {
          const targetTab = document.querySelector(array.tab)
          const accordionTarget = Array.from(
            targetTab.querySelectorAll("pre")
          ).find((el) =>
            el.textContent.trim().startsWith(array.preTextToReplace)
          )
          accordionTarget.insertAdjacentElement(
            "afterend",
            accordions[array.accordionIndex]
          )
          accordionTarget.remove()
        })
      }
    })
  })
}

export const addDownloads = (data) => {
  if (data.ecsPage.slug.includes("resources/past-reports")) {
    const tabs = document.querySelectorAll("[class='explorer-nav primary']")
    tabs &&
      tabs.forEach((tab) => {
        const section = tab.querySelectorAll("[class=content-body]")
        section &&
          section.forEach((sect) => {
            const docHref = sect.querySelector("p a").href
            const viewButton = sect.querySelector("p a").text === "View "
            let a = document.createElement("a")
            a.target = "_blank"
            a.href = docHref
            a.className = "download"
            a.innerText = "Download"
            a.download = docHref.split("/").slice(-1)[0]
            viewButton && sect && sect.querySelectorAll("p")[2].appendChild(a)
          })
      })
  }
}

export const addCircleGraph = (data, graphRef) => {
  if (
    data.ecsPage.slug.includes(
      "company/business-strategy-and-competitive-advantages"
    )
  ) {
    let source = document.querySelectorAll("[id$='2-panel']")[0]
    if (source && graphRef) {
      let explorerEle = source.querySelector(".explorer-nav.tab-panel-content")
      if (explorerEle) {
        const target = Array.from(explorerEle.querySelectorAll("pre")).find(
          (el) => el.textContent.trim() === "Add_Circle_Graph"
        )
        target.insertAdjacentElement("afterend", graphRef.current)
        target.remove()
      }
    }
  }
}

export const getContentForCircleGraph = (data, circleGraphSlices, page) => {
  if (
    ["/", `company/business-strategy-and-competitive-advantages`].includes(
      data.ecsPage.slug
    )
  ) {
    const textDetails =
      data.ecsPage?.content.components[circleGraphReplace[page].dcrIndexForBody]
        .segments[0].segmentItemDetails[0].parameterItemDetails[0].dcr
    for (var i = 1; i < 7; i++) {
      circleGraphSlices.push({
        title:
          textDetails.rows[circleGraphReplace[page].titleIndex(i)].columns[0]
            .text.copy,
        content:
          textDetails.rows[circleGraphReplace[page].contentIndex(i)].columns[0]
            .text.copy,
      })
    }

    return circleGraphSlices
  }
}
