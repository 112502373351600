import React, { useEffect, useState } from "react"
import DefaultHead from "../components/default-head"
import GlobalFooter from "../components/global-footer"
import GlobalHeader from "../components/global-header"
import CollapsibleSiteMessages from "../components/collapsible-site-messages"
import {
  Breadcrumbs,
  DisclaimerContextProvider,
  LinkContextProvider,
} from "trv-ebus-tcom-reactcomponents"
import AnchorLinkWithPreview from "../components/anchor-link/AnchorLinkWithPreview"
import { array, bool, object } from "prop-types"
import useWindowWidth from "../utilities/useWindowWidth"

const BaseLayout = ({
  head,
  disclaimers,
  breadcrumbs,
  sitewideMessages,
  globalHeader = {
    pageUrl: "",
  },
  children,
}) => {
  const [showAlert, setShowAlert] = useState(false)
  const basePath = globalHeader.pageUrl.split("/").slice(-2, -1)[0]
  const isKeyDriver = basePath === "drivers-of-sustained-value"
  const isIllustrative = basePath === "illustrative-initiatives"
  const getPageName = () => {
    if (isKeyDriver) {
      return "key-driver"
    } else if (isIllustrative) {
      return "illustrative-initiative"
    } else if (globalHeader.pageUrl === "illustrative-initiatives") {
      return "all-page"
    }
    return ""
  }
  const pageName = `${
    globalHeader.pageUrl.split("/").slice(-1)[0]
  } ${getPageName()}`
  const [isMobilenavOpen, setIsMobilenavOpen] = useState(false)
  const { isDesktop } = useWindowWidth()

  const onMenuToggleHandler = (e) => {
    if (!isDesktop) {
      setIsMobilenavOpen(e.target.checked)
    }
  }

  useEffect(() => {
    setIsMobilenavOpen(false)
  }, [isDesktop])

  return (
    <>
      <LinkContextProvider Link={AnchorLinkWithPreview}>
        <DisclaimerContextProvider>
          <DefaultHead
            {...head}
            {...globalHeader}
            showAlert={showAlert}
            onAlertClick={() => setShowAlert(false)}
          />
          <div className={`wrapper sustainability ${pageName}`}>
            {sitewideMessages && (
              <CollapsibleSiteMessages
                {...sitewideMessages}
                setAlert={(isVisible) => setShowAlert(isVisible)}
              />
            )}
            <GlobalHeader
              {...globalHeader}
              showAlert={showAlert}
              onAlertClick={() => setShowAlert(false)}
              onMenuToggleHandler={onMenuToggleHandler}
            />
            {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            {!isMobilenavOpen && (
              <>
                {children}
                <GlobalFooter disclaimers={disclaimers} />
              </>
            )}
          </div>
        </DisclaimerContextProvider>
      </LinkContextProvider>
    </>
  )
}

export default BaseLayout

BaseLayout.propTypes = {
  head: object,
  disclaimers: object,
  children: array,
  breadcrumbs: array,
  sitewideMessages: object,
  globalHeader: object,
  isMobilenavOpen: bool,
}
