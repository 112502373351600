import React from "react"
import { TdsGlobalFooter, TdsGlobalFooterLinks } from "@trv-tds/react"
import "./styles.scss"
import { object } from "prop-types"

const GlobalFooter = ({ disclaimers }) => (
  <TdsGlobalFooter
    sociallinks="fb tw yt li"
    className="custom-tds-global-footer"
  >
    <TdsGlobalFooterLinks slot="links" heading="PRODUCTS & SERVICES">
      <a href="https://www.travelers.com/individuals">For Individuals</a>
      <a href="https://www.travelers.com/business-insurance">For Businesses</a>
      <a href="https://www.travelers.com/claims/report-claim/index">
        Claim Services
      </a>
      <a href="https://www.travelers.com/resources">Prepare & Prevent</a>
    </TdsGlobalFooterLinks>
    <TdsGlobalFooterLinks slot="links" heading="OUR COMPANY">
      <a href="https://www.travelers.com/about-travelers">About Travelers</a>
      <a href="https://careers.travelers.com/">Careers</a>
      <a href="https://investor.travelers.com/">Investors</a>
      <a href="/">Sustainability</a>
    </TdsGlobalFooterLinks>
    <TdsGlobalFooterLinks slot="links" heading="CONNECT">
      <a href="https://www.travelers.com/contact-us">Customer Support</a>
      <a href="https://www.travelers.com/online-service">MyTravelers®</a>
      <a href="https://www.travelers.com/foragents">For Agents</a>
      <a href="https://www.travelers.com/about-travelers/accessibility">
        Accessibility Support
      </a>
    </TdsGlobalFooterLinks>
    <TdsGlobalFooterLinks slot="links" heading="LEGAL & COMPLIANCE">
      <a href="https://www.travelers.com/w3c/legal"> Terms of Service</a>
      <a href="https://www.travelers.com/privacy-statements/index.aspx">
        Privacy & Security
      </a>
      {/* OneTrust Cookies Settings button start  */}
      <a
        className="ot-sdk-show-settings"
        id="trv-ot-cookie-setting"
        href="javascript:void(0)"
      >
        Cookie Settings
      </a>
      {/* OneTrust Cookies Settings button end */}
      <a href="https://www.travelers.com/w3c/legal/Producer_Compensation_Disclosure.html">
        Producer Compensation Disclosure
      </a>
    </TdsGlobalFooterLinks>
    <div slot="copyright">
      <p>
        Travelers and The Travelers Umbrella are registered trademarks of The
        Travelers Indemnity Company in the U.S. and other countries.
        <br />© {new Date().getFullYear()} The Travelers Indemnity Company. All
        rights reserved.
      </p>
    </div>
    <div slot="disclaimer">{disclaimers}</div>
  </TdsGlobalFooter>
)

export default GlobalFooter

GlobalFooter.propTypes = {
  disclaimers: object,
}
