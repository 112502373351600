const tabOne = "[id$='1-panel']"
const tabTwo = "[id$='2-panel']"
const tabThree = "[id$='3-panel']"
const tabFour = "[id$='4-panel']"
const tabSeven = "[id$='7-panel']"

export const componentMapping = {
  accordion: {
    selector: "[class='accordion-interface']",
    pages: {
      "company/business-strategy-and-competitive-advantages": [
        {
          tab: tabOne,
          preTextToReplace: "Business_Strategy_Accordion",
          accordionIndex: 0,
        },
      ],
      "company/capital-and-risk-management": [
        {
          tab: tabOne,
          preTextToReplace: "Risk_Management_Approach_Accordion",
          accordionIndex: 0,
        },
        {
          tab: tabFour,
          preTextToReplace: "Balance_Approach_Accordion",
          accordionIndex: 1,
        },
      ],
      "company/investment-management": [
        {
          tab: tabOne,
          preTextToReplace: "Investment_Management_Accordion",
          accordionIndex: 0,
        },
      ],
      "governance/governance-practices": [
        {
          tab: tabTwo,
          preTextToReplace: "Director_Snapshot_Accordion",
          accordionIndex: 0,
        },
      ],

      "drivers-of-sustained-value/innovation": [
        {
          tab: tabOne,
          preTextToReplace: "Forces_Of_Change",
          accordionIndex: 1,
        },
        {
          tab: tabThree,
          preTextToReplace: "Innovation_Mindset",
          accordionIndex: 2,
        },
      ],
      "drivers-of-sustained-value/diversity-and-inclusion": [
        {
          tab: tabOne,
          preTextToReplace: "Diversity_Inclusion",
          accordionIndex: 0,
        },
      ],
      "drivers-of-sustained-value/human-capital-management": [
        {
          tab: tabThree,
          preTextToReplace: "Tenure_Accordion",
          accordionIndex: 0,
        },
        {
          tab: tabSeven,
          preTextToReplace: "Health_Wellness_Accordion",
          accordionIndex: 1,
        },
        {
          tab: tabSeven,
          preTextToReplace: "Financial_Security",
          accordionIndex: 2,
        },
      ],
      "drivers-of-sustained-value/ethics-and-values": [
        {
          tab: tabFour,
          preTextToReplace: "Responsible_Business_Practices",
          accordionIndex: 0,
        },
      ],
      "financial-performance": [
        {
          tab: tabThree,
          preTextToReplace: "Transcript_Accordion_Shareholder",
          accordionIndex: 0,
        },
        {
          tab: tabOne,
          preTextToReplace: "Net_Premium_Growth",
          accordionIndex: 1,
        },
        {
          tab: tabOne,
          preTextToReplace: "Underlying_Combined_Ratio",
          accordionIndex: 2,
        },
        {
          tab: tabOne,
          preTextToReplace: "Improving_Expense_Ratio",
          accordionIndex: 3,
        },
        {
          tab: tabOne,
          preTextToReplace: "Underwriting Income",
          accordionIndex: 4,
        },
        {
          tab: tabOne,
          preTextToReplace: "Higher_Cash_Flow",
          accordionIndex: 5,
        },
        {
          tab: tabOne,
          preTextToReplace: "Growing_Invested_Assets",
          accordionIndex: 6,
        },
        {
          tab: tabOne,
          preTextToReplace: "Return_On_Equity",
          accordionIndex: 7,
        },
        {
          tab: tabOne,
          preTextToReplace: "Adjusted_Book_Value",
          accordionIndex: 8,
        },
        {
          tab: tabOne,
          preTextToReplace: "Dividends_Per_Share",
          accordionIndex: 9,
        },
        {
          tab: tabOne,
          preTextToReplace: "Combined_Ratio_Accordion",
          accordionIndex: 10,
        },
        {
          tab: tabTwo,
          preTextToReplace: "Disciplined_Execution_Accordion",
          accordionIndex: 11,
        },
        {
          tab: tabOne,
          preTextToReplace: "Underlying_Underwriting_Income_Accordion",
          accordionIndex: 12,
        },
      ],
    },
  },
  pull_quote: {
    selector: "[class='specialized-content-print-wrapper']",
    pages: {
      "drivers-of-sustained-value/diversity-and-inclusion": [
        {
          tab: tabOne,
          preTextToReplace: "Pull_Quote_Diversity_Approach",
          accordionIndex: 0,
        },
      ],
    },
  },
  video_overlay: {
    selector: "[class='embedded-video-wrapper']",
    pages: {},
  },
}

export const circleGraphReplace = {
  homepage: {
    dcrIndexForBody: 2,
    titleIndex: function (n) {
      return 2 * n - 1
    },
    contentIndex: function (n) {
      return 2 * n
    },
  },
  "business-strategy-and-competitive-advantages": {
    dcrIndexForBody: 5,
    titleIndex: function (n) {
      return 2 * n + 1
    },
    contentIndex: function (n) {
      return 2 * n + 2
    },
  },
}
