import React, { useState } from "react"
import { TdsButton, TdsButtonContainer } from "@trv-tds/react"
import useWindowWidth from "../utilities/useWindowWidth"
import "./CircleGraph.scss"
import { string, array, bool, func } from "prop-types"

const BackButton = ({ handleBackButtonClick }) => (
  <TdsButtonContainer className="ca-home">
    <TdsButton
      variant="secondary"
      size="small"
      className="ca-home-button"
      onClick={() => handleBackButtonClick()}
    >
      Back
    </TdsButton>
  </TdsButtonContainer>
)
export default function CircleGraph({
  centerTitle,
  slices,
  competitiveAdv,
  slug,
}) {
  const [selectedSlice, setSelectedSlice] = useState(0)
  const { windowWidth } = useWindowWidth()
  const showBackButton = selectedSlice === 0 ? false : true
  const pageName = slug.split("/")[slug.split("/").length - 1]

  const handleSliceClick = (e, index) => {
    e.preventDefault()
    setSelectedSlice(index)
    if (
      (!pageName && index === 0) ||
      (pageName === "business-strategy-and-competitive-advantages" &&
        index === 0)
    ) {
      document
        .getElementById("value-drivers")
        .scrollIntoView({ behavior: "smooth" })
    }
  }

  const handleBackButtonClick = () => {
    setSelectedSlice(0)
  }

  return (
    <div
      id="competitive-advantages"
      className={competitiveAdv ? "page-competitive-adv" : ""}
    >
      <h1 className="competitive-advantages-header">
        Our Competitive Advantages
      </h1>
      <div className="ca-container">
        <div id="ca-graphic-container">
          <div
            className="ca-mask"
            style={{
              display: "block",
              backgroundImage: selectedSlice
                ? `url(/iw-images/sustainability/charts-graphs/circle-graph/ca-mask-${
                    selectedSlice + 1
                  }.png)`
                : null,
            }}
          ></div>
          <div
            id="ca-arrow"
            className={selectedSlice ? `ca-arrow-${selectedSlice + 1}` : null}
          ></div>
          <div id="ca-buttons">
            {slices.map((slice, index) => (
              <button
                key={slice.title}
                onClick={(e) => handleSliceClick(e, index)}
                className={selectedSlice === index ? "selected-slice" : ""}
                dangerouslySetInnerHTML={{
                  __html: index === 0 ? centerTitle : slice.title,
                }}
              ></button>
            ))}
          </div>
        </div>
        <div className="ca-content-container">
          {windowWidth <= 1250 && showBackButton && (
            <BackButton handleBackButtonClick={handleBackButtonClick} />
          )}
          <div
            className="ca-title"
            dangerouslySetInnerHTML={{
              __html: slices[selectedSlice].title,
            }}
          ></div>
          <div
            className="ca-content"
            dangerouslySetInnerHTML={{
              __html: slices[selectedSlice].content,
            }}
          ></div>
          {windowWidth > 1250 && showBackButton && (
            <BackButton handleBackButtonClick={handleBackButtonClick} />
          )}
        </div>

        <div className="ca-content-container-print-only">
          {slices.map((slice, index) => {
            return (
              <div key={index} className="ca-content-container-print-only">
                <div
                  className="ca-title"
                  dangerouslySetInnerHTML={{
                    __html: slice.title,
                  }}
                ></div>
                <div
                  className="ca-content"
                  dangerouslySetInnerHTML={{
                    __html: slice.content,
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

CircleGraph.propTypes = {
  centerTitle: string,
  slices: array,
  competitiveAdv: bool,
  slug: string,
}

BackButton.propTypes = {
  handleBackButtonClick: func.isRequired,
}
